<template>
    <div class="login-box">
        <van-nav-bar
            :title="''"
            fixed
            @click-left="onClickLeft"
            class="my-head null-head"
            :style="{background:'transparent'}"
        >
            <template #left   >
                <van-icon name="arrow-left"   />
            </template>
            <template #right  >
                <div class="login-one">
                    <div class="change" @click="showPopup">
                        <p class="qi-box">
                            <svg class="guoqi" aria-hidden="true">
                                <use :xlink:href="guoqiclass"></use>
                            </svg>
                            <span>{{lastLang | langtext }}</span>
                        </p>
                        <van-icon name="arrow-down" class="vicon"/>
                    </div>
                </div>
            </template>
        </van-nav-bar>
        <div class="login-top">
            <div class="logon-top-one">
                <img class="logo" src="../../assets/imgs/login-logo2.png" alt="">
            </div>
            <div class="login-two">
                <div>
                    <h1 class="login-title">{{$t('common.login')}}</h1>
                    <div class="login-welcome">{{$t('login.txt')}}</div>
                </div>
                <img src="../../assets/imgs/mans.png" class="login-img" alt="">
            </div>
        </div>
        <div class="login-content">
            <van-form @submit="onSubmit" class="my-form">
                <div class="van-cell van-field van-nohidden">
                    <div class="van-cell__title van-field__label">
                        <span>{{$t('login.phone')}}</span>
                    </div>
                    <div class="van-cell__value van-field__value van-phone">
                        <div class="tel-qu" v-if="!toggle">
                            <AreaNum  country-code="th"   @excountry="getCountryItem"   />
                        </div>
                        <div>
                            <div class="van-field__body">
                                <input   @input="checkPhone" @blur="phoneBlur" type="tel" v-model="login.phone" name="phone" :placeholder="$t('login.enter')+(lang=='en'?' ':'')+$t('login.phone')" class="van-field__control">
                                <div v-if="!phoneFlag" class="van-field__error-message qu-error">{{phoneErr}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <van-field
                    v-if="!toggle"
                    maxlength="6"
                    v-model="login.captcha"
                    type="text"
                    name="captcha"
                    :label="$t('login.captcha')"
                    :placeholder="$t('login.enter')+(lang=='en'?' ':'')+$t('login.captcha')"
                    :rules="[{ required: true, message:$t('login.required_captcha')},{pattern:smsReg,message:$t('login.ture_captcha'),trigger:'onBlur'}]"
                >
                    <template v-if="!toggle" #button>
                        <p v-if="!isSending" size="small"  @click="getCaptcha"  class="sms-btn">{{txt}}</p>
                        <p v-if="isSending"  size="small"    class="sms-btn color-disable">{{txt}}</p>
                    </template>
                </van-field>
                 <van-field
                    v-if="toggle"
                    v-model="login.password"
                    :type="pass_type"
                    name="password"
                    :label="$t('login.password')"
                    :placeholder="$t('login.enter_pass')"
                    :rules="[{ required: true, message: $t('login.empty_pass') },
                    {
                        pattern:passPattern,message:$t('login.pass_error')
                    }
                    ]"

                    :right-icon="pass_type=='password'?'eye':'closed-eye'"
                    class="inp_pass"
                    @click-right-icon="changePassType"
                />
                <p class="choosetype" @click="chooseType"> {{!toggle?$t('login.password'):$t('login.captcha')}} {{$t('common.login')}}</p>
                <div class="btn-box">
                    
                    <van-button @click="btnClick" class="login-btn"  block type="info" native-type="submit">
                    {{$t('common.login')}}
                    </van-button>
                </div>
            </van-form>
            <div class="login-other">
                <p>
                    <span class="p-one">{{$t('login.no_account')}}</span>
                    <span class="p-two" @click="gotoRegister">{{$t('login.register_now')}}</span>
                </p>
                <p >
                    <span class="p-wang" @click="gotoforgotpass">{{$t('login.forgot_pass')}}?</span>
                </p>
            </div>
        </div>


        <van-popup class="login-popup" v-model="show" position="bottom"  :style="{ height: '5.3333rem' }" duration="0.2" >
            <div class="login-popup-box">
                <div class="pop-top">
                    <p>{{$t('login.set_lang')}}</p>
                    <van-icon name="cross" class="pop-close" @click="show=false" />
                </div>
                <div class="pop-btns">
                    <van-button class="btn-normal" @click="changeLang('cn')" :class="lang=='cn'?'btn-active':''" >中文</van-button>
                    <van-button class="btn-normal"  @click="changeLang('th')" :class="lang=='th'?'btn-active':''">ไทย</van-button>
                    <van-button class="btn-normal"  @click="changeLang('en')" :class="lang=='en'?'btn-active':''">English</van-button>
                </div>
                <van-button class="sure-btn" @click="sureChange">{{$t('login.sure')}}</van-button>
            </div>
        </van-popup>
    </div>
</template>


<script>
import {passReg,smsReg,phoneReg} from "@/common/validate"
import {vantLocales} from "@/main.js"
import {Toast,Notify} from "vant"
import {mapState,mapMutations,mapGetters} from "vuex"
let timer = null;
export default {
    name:'signin',
    data(){
        return {
            toggle:true,  // 密码登录  false 验证码登录
            account:'',
            password:'',
            show:false,
            lang:localStorage.getItem("langData") || 'th',
            lastLang:localStorage.getItem("langData") || 'th',
            pass_type:"password",
            passPattern:passReg,
            login:{
                area:'66'
            },
            phoneFlag:true,
            phoneErr:'',
            count:120,
            txt:this.$t('login.send_captcha'),
            isSending:false,
            smsReg,
        }
    },
    computed:{
        guoqiclass(){
            if(this.lastLang=="cn"){
                return "#iconzhongguo"
            }else if(this.lastLang=="en"){
                return "#iconyingguo"
            }else{
                return "#icontaiguo"
            }
            return this.lastLang
        },
        ...mapState(['dashboard'])
    },
    methods:{
        onClickLeft(){
            var fromRoute = localStorage.getItem('fromRoute');
            if(fromRoute=="main-walletIndex"||fromRoute=="main-mineIndex"||fromRoute=="mine-info"||fromRoute=="resetloginpass"||fromRoute=="register"){
                this.$router.push({name:'main-homeIndex'})
            }else{
                this.$router.go(-1)
            }
        },
        ...mapMutations(['setdashboard','setCardList']),
        getdashboard(){
            this.$ajax.dashboard()
            .then(res=>{
                this.setdashboard(res.data);
                localStorage.setItem('dashboard',JSON.stringify(res.data))
                // this.$router.go(-1)  // 直接返回 
            })
        },
        chooseType(){
            this.toggle = !this.toggle;
        },
        btnClick(){
            // 手机检测
            this.checkPhone();
        },
        getCountryItem(data){
            this.login.area = data.dialCode;
        },
        getCaptcha(){
            
            if(this.login.phone&&this.phoneFlag){
                this.txt = this.count + ' S';
                this.isSending = true;
                timer = setInterval(()=>{
                    if(this.count>0){
                        this.count--;
                        this.txt = this.count + ' S';
                        this.isSending = true;
                    }else{
                        // timer = null;
                        clearInterval(timer)
                        this.count = 120;
                        this.txt = this.$t('login.send_captcha');
                        this.isSending = false;
                    }
                },1000);
                
                this.$ajax.sendMsg({
                    area:this.login.area,
                    phone:this.login.phone,
                    type:8,   // 登录验证码 
                })
                .then(res=>{
                    if(res.code==200){
                        Notify({ type: 'success', message: res.msg });
                    }
                })

            }else{
                 Toast(this.$t("login.sure_sms"))   
            }
        },
        phoneBlur(){
            if(!this.login.phone){
                this.phoneErr = this.$t('login.empty_phone');
                this.phoneFlag = false;
            }
        },
        checkPhone(){
            if(this.login.phone){
                if(phoneReg.test(this.login.phone)){
                    // 通过
                    this.phoneFlag = true;
                    this.phoneErr = ""
                }else{
                    this.phoneFlag = false;
                    this.phoneErr = this.$t("login.err_phone")
                }
            }else{
                // null
                this.phoneErr = this.$t('login.empty_phone');
                this.phoneFlag = false;
            }
        },
        changePassType(){
            this.pass_type = this.pass_type == "password"?"text":"password"
        },
        showPopup(){
            this.lang = localStorage.getItem("langData") || 'th',
            this.show = true;
        },
        onSubmit(value){
            
            // 是否显示成功提示
            // 是否自定义 msg 
            if(!this.phoneFlag){
                this.checkPhone(); 
            }else{
                if(this.toggle){
                    // 密码登录 
                    this.$ajax.pwdlogin({
                        phone:this.login.phone,
                        password:this.login.password,
                        area:this.login.area,
                    })
                    .then(res=>{
                        if(res.code==200){
                            // Toast("登录成功,进入主页")
                            localStorage.setItem("access_token",res.data.access_token);
                            // this.$router.push({name:"main-homeIndex"});
                            this.getdashboard()
                            this.$router.go(-1)  // 直接返回 
                        } 
                    })
                }else{
                    // 验证码登录
                    this.$ajax.login({
                        phone:this.login.phone,
                        password:this.login.captcha,
                        area:this.login.area,
                    })
                    .then(res=>{
                        if(res.code==200){
                            // Toast("登录成功,进入主页")
                            localStorage.setItem("access_token",res.data.access_token);
                            // this.$router.push({name:"main-homeIndex"});
                            this.getdashboard()
                            this.$router.go(-1)
                        }
                    })
                }
                
            }
        },
        gotoRegister(){
            this.$router.push({name:'register'})
        },
        gotoforgotpass(){
            this.$router.push({name:'resetloginpass'})
        },
        changeLang(lang){
            this.lang = lang;
        },
        sureChange(){
            localStorage.setItem('langData',this.lang);
            this.$i18n.locale = this.lang;
            this.lastLang = this.lang;
            this.show = false;
            vantLocales(this.lang)
        }
    },
    mounted(){
        if(this.$route.query.phone){
            this.login = {...this.login,phone:this.$route.query.phone}
        }
    }
}
</script>
